<template>
  <div class="terms">
    <div class="title font-md">
      <b>{{ state.terms.title }}</b>
    </div>
    <div class="desc font-sm" v-if="state.terms.desc">{{ state.terms.desc }}</div>
    <div class="article font-sm">
      <ul class="tight">
        <li v-for="(s, idx) in state.terms.sections" :key="idx">
          <div class="subject">{{ s.subject }}</div>
          <div class="content" v-html="s.content"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalTerms";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.terms = definitions.terms.find(t => t.name === modalParams.name);
      store.commit("tuneModal", {component, size: modalParams.size || "lg"});
    });

    const state = reactive({
      terms: {
        title: "",
        sections: []
      },
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.terms {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px25;
  }

  .desc {
    white-space: pre-line;
    margin-bottom: $px25;
    color: #666;
  }

  .article {
    > ul > li {
      .content {
        white-space: pre-line;
      }

      .subject {
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-bottom: $px25;
      }
    }

    &::v-deep {
      table {
        border-bottom: $px1 solid #dee2e6;

        th {
          border-bottom-width: $px1;
          background: $colorBackground;
        }
      }
    }
  }
}
</style>